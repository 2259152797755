<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="mfaEnrollActivate">
      <template #title>
        Verify your account.
      </template>
      <template #main>
        <div class="mb-6">
          <span>Enter the 6 digit code {{ enrollChallengeDestination }}.</span>
        </div>
        <MfaCodeInput
          @submit="verify"
          @resend="resendCode"
          :dontChallengeOption="false"
          :submitting="submitting"
        ></MfaCodeInput>
      </template>
    </Card>
  </div>
</template>

<script>
import {useIdentityStore} from '~/store/identity';
import Card from '~/components/identity/Card.vue';
import MfaCodeInput from './MfaCodeInput.vue';
import { phone } from '~/libs/format'

export default {
  name: 'IdentityMfaEnrollActivate',
  components: {
    Card,
    MfaCodeInput,
  },
  async setup() {
    return {
      identityStore: useIdentityStore(),
    }
  },
  data() {
    return {
      submitting: false,
      enrollChallengeDestination: '',
    }
  },
  beforeMount() {
    switch (this.identityStore.selectedMfaMethod) {
      case 'sms':
        this.enrollChallengeDestination = 'sent to ' + phone(this.identityStore.enrollChallengeDestination?.replace('+1', ''))
        break
      case 'call':
        this.enrollChallengeDestination = 'delivered via phone call to ' + phone(this.identityStore.enrollChallengeDestination?.replace('+1', ''))
        break
      case 'totp':
        this.enrollChallengeDestination = 'from your authenticator app'
        break
    }
  },
  methods: {
    async verify(value) {
      this.submitting = true
      try {
        await this.identityStore.mfaEnrollActivate(this.identityStore.selectedMfaMethod, value.code, value.rememberDevice)
      } catch (error) {
        this.showErrorAlert(error.message ?? 'Failed to verify code. Please try again.')
        this.submitting = false
      }
    },
    async resendCode() {
      try {
        await this.identityStore.mfaEnroll(this.identityStore.selectedMfaMethod, this.identityStore.enrollChallengeDestination, true)
        this.showSuccessAlert('Code resent.')
      } catch (error) {
        this.showErrorAlert(error.message ?? 'Failed to resend code. Please try again.')
      }
    },
    showErrorAlert(message) {
      if (this.$refs?.mfaEnrollActivate?.$refs?.alert) {
        this.$refs.mfaEnrollActivate.$refs.alert.openDanger({
          content: message,
          untrusted: true
        })
      }
    },
    showSuccessAlert(message) {
      if (this.$refs?.mfaEnrollActivate?.$refs?.alert) {
        this.$refs.mfaEnrollActivate.$refs.alert.openSuccess({
          content: message,
          untrusted: true
        })
      }
    },
  }
}
</script>
